import React from 'react'
import styled from 'styled-components'

export const FullBleed = styled.div<{ background?: string; dark?: boolean }>`
  background-image: ${({ background }) =>
    background ? `url(${background})` : 'none'};
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  /* background-color: ${({ dark }) => (dark ? 'black' : 'white')}; */
  background: #9796f0;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #cdc1ff, #9796f0);  /* Chrome 10-25, Safari 5.1-6 */
  background: ${({ dark }) => (dark ? 'linear-gradient(to top, #cdc1ff, #9796f0)' : 'white')}; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: ${({ dark }) => (dark ? 'white' : 'black')};
  width: 100%;
  grid-column: 1 / 4 !important;
  margin-top: ${({dark}) => (dark ? '80px' : '0')};
  padding-bottom: 96px;

  @media (min-width: 420px) {
    > * {
      display: grid;
      overflow-x: hidden;

      grid-template-columns:
        1fr
        min(1200px, 100%)
        1fr;

      > * {
        grid-column: 2;
      }
    }
  }
`
